import Bredcrumb from '../../HomeMain/Bredcrumb'
import React from 'react'
import Gallery5 from '../../HomeMain/Gallery5'

const Main = () => {
  return (
    <>
      
      <div className="gallery-block">
        <Gallery5/>
      </div>
    </>
  )
}

export default Main