import Bredcrumb from '../../HomeMain/Bredcrumb'
import React from 'react'
import Gallery2 from '../../HomeMain/Gallery2'

const Main = () => {
  return (
    <>
      
      <div className="gallery-block">
        <Gallery2/>
      </div>
    </>
  )
}

export default Main